<template>
  <b-tab title="학습내역">
    <section class="content">
      <b-row class="p-2">
        <b-col>
          <b-input-group size="sm">
            <datepicker
              id="from-datepicker-util"
              v-model="start_date"
              v-on:selected="changeStartDate"
              name="start_date"
              :value="start_date.date"
              :language="languages[language]"
              :format="start_format"
              placeholder="시작일"
              input-class="form-control custom-select-sm"
            ></datepicker>
            ~
            <datepicker
              id="to-datepicker-util"
              v-model="end_date"
              v-on:selected="changeEndDate"
              name="end_date"
              :value="end_date.date"
              :language="languages[language]"
              :format="end_format"
              placeholder="종료일"
              input-class="form-control custom-select-sm"
            ></datepicker>
          </b-input-group>
        </b-col>
        <b-col>
          <div class="d-flex flex-row-reverse">
            <b-pagination
              v-model="page"
              :total-rows="records"
              :per-page="rowPerPage"
              @change="setPage"
              align="right"
            ></b-pagination>
          </div>
        </b-col>
      </b-row>

      <b-table-simple hover small caption-top responsive :busy="isBusy" show-empty>
        <b-thead head-variant="dark" class="text-center">
          <b-tr>
            <b-th>
              학습일시
            </b-th>
            <b-th>
              카테고리(댸)
            </b-th>
            <b-th>강의번호 </b-th>
            <b-th>
              제목
            </b-th>
            <b-th>적용 상품 </b-th>
            <b-th>
              재학습 기간
            </b-th>
            <b-th>
              지급 포인트
            </b-th>
          </b-tr>
        </b-thead>

        <b-tbody class="text-center">
          <b-tr v-for="(history, index) in historyItems" :key="index">
            <b-td>{{ history.reg_dt }}</b-td>
            <b-td>{{ history.category }}</b-td>
            <b-td> {{ history.lc_no }}</b-td>
            <b-td> {{ history.lecture_title }}</b-td>
            <b-td> {{ history.product_title }} </b-td>
            <b-td> {{ history.expire_dt }}</b-td>
            <b-td> - </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </section>
  </b-tab>
</template>

<script>
// import { user_deposit_list, deposit_set, user_deposit_status } from '@/api/deposit'
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import * as lang from 'vuejs-datepicker/src/locale'
import { getStudyHistory } from '../../api/user'
import { getVoucherType } from '../../api/code'

var now = new Date()
var monthFirstDate = new Date(now.getUTCFullYear(), now.getMonth(), 1)
var monthLastDate = new Date(now.getUTCFullYear(), now.getMonth() + 1, 0)

export default {
  name: 'UserStudyHistory',
  components: { Datepicker },
  data() {
    return {
      page: 1,
      rowPerPage: 20,
      records: 0,
      isBusy: false,
      usersn: this.$route.params.usersn,
      start_date: this.$moment(monthFirstDate).format('YYYY-MM-DD'),
      end_date: this.$moment(monthLastDate).format('YYYY-MM-DD'),
      selected_status: '01',

      confrm_amt: '',
      reject_reason: '',
      historyItems: [],
      striped: true,
      bordered: true,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      tableVariant: '',
      noCollapse: false,
      headVariant: 'dark',

      filter: '',
      days: [6, 0], // Highlight Saturday's and Sunday's,
      start_format: 'yyyy-MM-dd',
      end_format: 'yyyy-MM-dd',
      eventMsg: null,
      language: 'ko',
      languages: lang
    }
  },
  watch: {
    selected_status() {
      this.reset()
      return
    }
  },
  methods: {
    async getList() {
      this.isBusy = true

      var startDT = this.$moment(this.start_date).format('YYYYMMDD')
      var endDT = this.$moment(this.end_date).format('YYYYMMDD')
      const resp = await getStudyHistory(this.usersn, this.page, this.rowPerPage, startDT, endDT)
      if (resp.code === '0000') {
        this.historyItems = resp.data.list
        this.records = resp.data.totCnt
      } else {
        alert(resp.message)
      }
      // this.couponItems = await user_deposit_list(
      //   this.usersn,
      //   this.$moment(this.start_date).format('YYYY-MM-DD'),
      //   this.$moment(this.end_date).format('YYYY-MM-DD'),
      //   this.page
      // )
      this.isBusy = false
    },
    setPage(page) {
      this.page = page
      this.getList()
    },
    changeStartDate(val) {
      this.start_date = this.$moment(val).format('YYYY-MM-DD')
      this.end_date = this.$moment(this.end_date).format('YYYY-MM-DD')
      this.page = 1
      this.getList()
    },
    changeEndDate(val) {
      this.start_date = this.$moment(this.start_date).format('YYYY-MM-DD')
      this.end_date = this.$moment(val).format('YYYY-MM-DD')
      this.page = 1
      this.getList()
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped>
table tbody tr .td-title {
  color: #fff;
  background-color: #212529;
  border-color: #383f45;
  text-align: center;
}

table tbody tr .td-data {
  color: #000;
  background-color: #ffffff;
  border-color: #383f45;
  text-align: right;
  padding-right: 5px;
}
</style>
