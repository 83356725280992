<template>
  <b-tab title="결제내역" active>
    <section class="content">
      <b-row class="p-2">
        <b-col>
          <b-input-group size="sm">
            <datepicker
              id="from-datepicker-util"
              v-model="start_date"
              v-on:selected="changeStartDate"
              name="start_date"
              :value="start_date.date"
              :language="languages[language]"
              :format="start_format"
              placeholder="시작일"
              input-class="form-control custom-select-sm"
            ></datepicker>
            ~
            <datepicker
              id="to-datepicker-util"
              v-model="end_date"
              v-on:selected="changeEndDate"
              name="end_date"
              :value="end_date.date"
              :language="languages[language]"
              :format="end_format"
              placeholder="종료일"
              input-class="form-control custom-select-sm"
            ></datepicker>
          </b-input-group>
        </b-col>
        <b-col>
          <div class="d-flex flex-row-reverse">
            <b-pagination
              v-model="page"
              :total-rows="records"
              :per-page="rowPerPage"
              @change="setPage"
              align="right"
            ></b-pagination>
          </div>
        </b-col>
      </b-row>

      <b-table-simple hover small caption-top responsive :busy="isBusy" show-empty>
        <b-thead head-variant="dark" class="text-center">
          <b-tr>
            <b-th>
              거래 일시
            </b-th>
            <b-th>
              결제 수단
            </b-th>
            <b-th>결제금액 </b-th>
            <b-th>
              구입상품
            </b-th>
            <b-th>유효기간 </b-th>
            <b-th>
              상태
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody class="text-center">
          <b-tr v-for="(payment, index) in paymentItems" :key="index">
            <b-td>{{ payment.reg_dt }}</b-td>
            <b-td>{{ convertPRType(payment.ord_way_cd) }}</b-td>
            <b-td>{{ payment.price }}</b-td>
            <b-td>{{ payment.title }}</b-td>

            <template v-if="payment.pr_type == '01'"
              >{{/** 수강권*/}}
              <b-td> {{ payment.expire_date }}</b-td>
            </template>
            <template v-else>
              <b-td>{{ payment.start_date }} ~ {{ payment.end_date }}</b-td>
            </template>
            <template v-if="payment.pr_type == '01'"
              >{{/** 수강권*/}}
              <b-td> {{ payment.times }} </b-td>
            </template>
            <template v-else>
              <b-td> {{ Number(payment.use_count) }} / {{ payment.times }}</b-td>
            </template>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </section>
  </b-tab>
</template>

<script>
import { getPayment } from '@/api/user'
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import * as lang from 'vuejs-datepicker/src/locale'
import { getVoucherType, getPaymentType } from '../../api/code'
import RecommandUserView from '../util/RecommList'

var now = new Date()
var monthFirstDate = new Date(now.getUTCFullYear(), now.getMonth(), 1)
var monthLastDate = new Date(now.getUTCFullYear(), now.getMonth() + 1, 0)

export default {
  name: 'UserPayment',
  components: { Datepicker, RecommandUserView },
  data() {
    return {
      page: 1,
      rowPerPage: 20,
      records: 0,
      isBusy: false,
      usersn: this.$route.params.usersn,
      start_date: this.$moment(monthFirstDate).format('YYYY-MM-DD'),
      end_date: this.$moment(monthLastDate).format('YYYY-MM-DD'),
      selected_status: '01',
      deposit_sn: '',
      itemsStatus: '',
      txid: '',
      confrm_amt: '',
      reject_reason: '',
      txid_disabled: false,
      confrm_amt_disabled: false,
      reject_reason_disabled: false,

      paymentItems: [],
      striped: true,
      bordered: true,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      tableVariant: '',
      noCollapse: false,
      headVariant: 'dark',

      filter: '',
      days: [6, 0], // Highlight Saturday's and Sunday's,
      start_format: 'yyyy-MM-dd',
      end_format: 'yyyy-MM-dd',
      eventMsg: null,
      language: 'ko',
      languages: lang,
      VOUCHER_CODE: '' /** 상품종류(118)-수강권/구독권*/,
      PAYMENT_CODE: '' /**결제수단(115) */
    }
  },
  watch: {
    selected_status() {
      this.reset()
      return
    }
  },
  methods: {
    convertPRType(code) {
      if (code) {
        console.log(code)
        const name = this.PAYMENT_CODE.find(value => value.cd_value === code).cd_name_kr
        return name
      } else return ''
    },
    async initVoucherType() {
      var resp = await getVoucherType()
      this.VOUCHER_CODE = resp
    },
    async initPaymentType() {
      var resp = await getPaymentType()
      this.PAYMENT_CODE = resp
    },
    async getList() {
      this.isBusy = true

      this.start_date = this.$moment(this.start_date).format('YYYY-MM-DD')
      var startDT = this.start_date.replace(/-/gi, '')
      var endDT = this.$moment(this.end_date).format('YYYYMMDD')
      const resp = await getPayment(this.usersn, this.page, this.rowPerPage, startDT, endDT)
      if (resp.code === '0000') {
        this.paymentItems = resp.data.list
        this.records = resp.data.totCnt
      } else {
        alert(resp.message)
      }

      this.isBusy = false
    },
    setPage(page) {
      this.isBusy = true
      this.page = page
      this.getList()
      this.isBusy = false
    },
    // async getStatus(num) {
    //   /* 상태값 모달창 */
    //   this.itemsStatus = await user_deposit_status(num)
    //   this.selected_status = this.itemsStatus.DEPOSIT_STATUS
    //   if (this.selected_status == '02') {
    //     this.reject_reason_disabled = true
    //     this.reject_reason = this.itemsStatus.REJECT_REASON
    //   } else if (this.selected_status == '03') {
    //     this.txid = this.itemsStatus.TRANS_TXID
    //     this.confrm_amt = this.itemsStatus.CONFRM_AMT
    //   }
    //   this.reset()
    //   return
    // },
    // reset() {
    //   if (this.selected_status == '01') {
    //     this.txid_disabled = true
    //     this.confrm_amt_disabled = true
    //     this.reject_reason_disabled = true
    //   } else if (this.selected_status == '02') {
    //     this.txid_disabled = true
    //     this.confrm_amt_disabled = true
    //     this.reject_reason_disabled = false
    //   } else if (this.selected_status == '03') {
    //     this.txid_disabled = false
    //     this.confrm_amt_disabled = false
    //     this.reject_reason_disabled = true
    //   }
    // },
    changeStartDate(val) {
      this.start_date = this.$moment(val).format('YYYY-MM-DD')
      this.end_date = this.$moment(this.end_date).format('YYYY-MM-DD')
      this.page = 1
      this.getList()
    },
    changeEndDate(val) {
      this.start_date = this.$moment(this.start_date).format('YYYY-MM-DD')
      this.end_date = this.$moment(val).format('YYYY-MM-DD')
      this.page = 1
      this.getList()
    }
    // resetModal_approval() {
    //   this.confrm_amt = ''
    //   this.txid = ''
    //   this.reject_reason = ''
    // },
    // handleOk_approval(bvModalEvt) {
    //   bvModalEvt.preventDefault()
    //   this.handleSubmit_approval()
    // },
    // handleSubmit_approval() {
    //   deposit_set(
    //     this.deposit_sn,
    //     this.confrm_amt,
    //     this.txid,
    //     this.selected_status,
    //     this.reject_reason
    //   )
    //   this.$nextTick(() => {
    //     this.$bvModal.hide('modal-1')
    //     this.setCondition()
    //   })
    // }
  },
  mounted() {
    this.initVoucherType()
    this.initPaymentType()
    this.getList()
  }
}
</script>

<style scoped>
table tbody tr .td-title {
  color: #fff;
  background-color: #212529;
  border-color: #383f45;
  text-align: center;
}

table tbody tr .td-data {
  color: #000;
  background-color: #ffffff;
  border-color: #383f45;
  text-align: right;
  padding-right: 5px;
}
</style>
