import { axiosGet, axiosPut, axiosPost } from './config'
// import router from '../router'
// import store from '../store'

/**
 * 상품 등록
 * @param {*} pr_type
 * @param {*} times
 * @param {*} title
 * @param {*} price
 * @param {*} net_price
 * @param {*} disc_per
 * @param {*} contents HTML 코드
 * @returns
 */
export async function createVoucher(
  pr_type,
  times,
  title,
  price,
  net_price,
  disc_per,
  contents,
  in_app_id = '',
  in_app_type = 'WEB'
) {
  try {
    var url = 'product'
    price = price.toString().replace(/,/g, '')
    net_price = net_price.toString().replace(/,/g, '')

    var response = await axiosPost(url, {
      pr_type,
      times,
      title,
      price,
      net_price,
      disc_per,
      contents,
      in_app_id,
      in_app_type
    })
    console.log(`response: ${response}`)
    return response
  } catch (error) {
    console.log(error)
  }
  return null
}

/**
 * 상품 수정
 * @param {*} pr_snm
 * @param {*} pr_type
 * @param {*} titmes
 * @param {*} title
 * @param {*} price
 * @param {*} net_price
 * @param {*} disc_per
 * @param {*} contents HTML 코드
 * @returns
 */
export async function editVoucher(
  pr_sn,
  pr_type,
  times,
  title,
  price,
  net_price,
  disc_per,
  contents,
  in_app_id = '',
  in_app_type = 'WEB'
) {
  price = price.toString().replace(/,/g, '')
  net_price = net_price.toString().replace(/,/g, '')
  try {
    var url = 'product'
    var response = await axiosPut(url, {
      pr_sn,
      pr_type,
      times,
      title,
      price,
      net_price,
      disc_per,
      contents,
      in_app_id,
      in_app_type
    })
    return response
  } catch (error) {
    console.log(error)
  }
  return null
}

/**
 * 상품 상태값 변경
 * @param {*} pr_sn
 * @param {*} pr_stat_cd
 * @returns
 */
export async function editVoucherState(pr_sn, pr_stat_cd) {
  try {
    var url = 'product/stat'
    var response = await axiosPut(url, { pr_sn, pr_stat_cd })
    console.log(JSON.stringify(response))
    return response
  } catch (error) {
    console.log(error)
  }
  return null
}

export async function getProducts() {
  try {
    var url = 'product'
    var response = await axiosGet(url)
    return response
  } catch (error) {
    console.log(error)
  }
  return null
}
