<template>
  <div>
    <b-modal title="추천인 추가" ref="modal_view" :hide-footer="true" @shown="init">
      <b-form-group id="fieldset-1">
        <b-input-group prepend="추천코드" class="mt-3">
          <b-form-input
            v-model="recom_code"
            :class="is_valid == 'Y' ? 'is-valid' : is_valid == 'N' ? 'is-invalid' : ''"
            @change="is_verify = false"
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="info" @click="checkValid">검증</b-button>
          </b-input-group-append>
        </b-input-group>
        <small v-if="is_valid_text">
          {{ is_valid_text }}
        </small>

        <b-input-group prepend="가입이벤트" v-if="recom_product_title" class="mt-2">
          <b-form-input :value="recom_product_title"></b-form-input>
          <b-input-group-append is-text v-if="recom_pr_sn">
            <b-form-checkbox v-model="is_event" name="checkbox-1" value="Y" unchecked-value="N"
              >이벤트 적용
            </b-form-checkbox>
          </b-input-group-append>
        </b-input-group>

        <br /><br />
        <b-button @click="close">취소</b-button>
        <b-button variant="danger" @click="submit" class="ml-2">적용</b-button>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { checkRecomCode, searchRecomProduct, setRecomCode } from '../../api/user'

export default {
  data() {
    return {
      recom_code: '',
      recom_sn: null,
      is_event: 'N',
      is_valid: null,
      is_valid_text: '',
      recom_product_title: null,
      recom_pr_sn: null,
      is_verify: false
    }
  },
  props: {
    user_sn: null
  },
  methods: {
    close() {
      this.$refs['modal_view'].hide()
    },
    async submit() {
      if (this.recom_code == '') {
        alert('추천코드를 입력해주세요')
        return
      }
      if (!this.is_verify) {
        alert('검증 확인 후 진행하세요')
        return
      }
      const response = await setRecomCode(
        this.recom_code,
        this.recom_sn,
        this.user_sn,
        this.is_event
      )
      if (response.code === '0000') {
        alert(`추천 코드 적용(${response.data.is_insert}) 이벤트 적용(${response.data.is_event})`)
        this.$emit('refresh')
        this.close()
      } else {
        alert(response.message)
      }
    },
    async checkValid() {
      if (this.recom_code) {
        const resp = await checkRecomCode(this.recom_code)
        if (resp.code === '0000' && resp.data && resp.data.user_sn) {
          this.recom_sn = resp.data.user_sn
          this.is_valid = 'Y'
        } else {
          this.is_valid_text = resp.message
          this.is_valid = 'N'
          this.recom_sn = null
        }
        this.selectEvent()
        this.is_verify = true
      } else {
        alert('추천코드를 입력해주세요')
        this.is_valid = 'N'
      }
    },
    async selectEvent() {
      const resp = await searchRecomProduct(this.recom_code)
      if (resp.code === '0000' && resp.data && resp.data.title) {
        this.recom_product_title = resp.data.title
        this.recom_pr_sn = resp.data.pr_sn
      } else {
        this.recom_product_title = '적용할 가입 이벤트가 없습니다.'
        this.recom_pr_sn = null
        this.is_event = 'N'
      }
    },
    init() {
      this.recom_code = ''
      this.recom_sn = null
      this.is_event = 'N'
      this.is_valid = null
      this.is_valid_text = ''
      this.recom_product_title = null
      this.recom_pr_sn = null
      this.is_verify = false
    }
  },
  mounted() {}
}
</script>
